<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }" v-if="group">
    <feather-icon
      icon="EditIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
    <template v-if="$store.state.AppActiveUser.type > 0">
      <feather-icon
        icon="XCircleIcon"
        svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
        @click="removeRecord"
      />
    </template>
  </div>
</template>

<script>
import i18n from "../../../../i18n/i18n";

export default {
  
  data: () => ({
    group: null
  }),
  methods: {
    editRecord() {
      this.$router
        .push(`/group/${this.group.id}`)
        .catch(() => {});
    },
    removeRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: i18n.t("group.actions.delete"),
        text: `${i18n.t("group.messages.confirm.delete")} ${
          this.group.group_name
        }?`,
        accept: this.delete,
        acceptText: i18n.t("actions.delete"),
        cancelText: i18n.t("actions.cancel")
      });
    },
    delete() {
      this.$store
        .dispatch("httpRequest", {
          method: "delete",
          url: `machine-group/${this.group.id}`
        })
        .then(() => {
          this.showSuccess();
          this.$parent.$parent.loadData();
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    showSuccess() {
      this.$vs.notify({
        color: "success",
        title: i18n.t("dialog.success"),
        text: i18n.t("group.messages.success.delete")
      });
    }
  },
  mounted() {
    this.group = this.params.data;
  }
};
</script>
