<template>
  <div id="content">
    <div id="list-content" class="vx-card p-6 vs-con-loading__container">
      <vs-row>
        <vs-button
          class="mt-5 mb-5"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="addItem"
          >{{ $t("group.actions.new") }}</vs-button
        >
      </vs-row>
      <div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="data"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        >
        </ag-grid-vue>

        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import GroupCellRendererActions from "./cell-renderer/GroupCellRendererActions.vue";

export default {
  components: {
    AgGridVue,
    GroupCellRendererActions
  },
  data: () => ({
    data: [],
    dataRaw: null,

    // AgGrid
    maxPageNumbers: 7,
    gridOptions: {
      localeTextFunc: function(key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true
    },
    gridApi: null,
    defaultColDef: {
      sortable: true,
      suppressMenu: true,
      floatingFilter: true
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        field: "id",
        width: 150,
        suppressSizeToFit: true,
        filter: true
      },
      {
        headerName: i18n.t("common.name"),
        field: "group_name",
        sort: "asc",
        width: 250,
        filter: true
      },
      {
        headerName: i18n.t("actions.title"),
        width: 100,
        suppressSizeToFit: true,
        field: "actions",
        cellRendererFramework: "GroupCellRendererActions"
      }
    ]
  }),
  watch: {
    "$store.state.windowWidth"(val) {
      this.resizeTable(val);
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine-group/all" })
        .then(data => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          self.data = data;
          self.dataRaw = data;
        })
        .catch(error => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    addItem() {
      this.$router.push({
        name: "group-new",
        params: { group: {}, new: true }
      });
    },
    resizeTable(width) {
      if (width <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("actions", null);
      } else {
        this.gridOptions.columnApi.setColumnPinned("actions", "right");
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loadData();
    this.resizeTable(this.$store.state.windowWidth);
  }
};
</script>

<style lang="scss" scoped></style>
